<template>
  <div>
    Vous pouvez utiliser les variables suivantes dans votre message :

    <br />
    <br />

    <ul>
      <li><span class="text-primary">[nom]</span> : Nom du DG</li>

      <li>
        <span class="text-primary">[entreprise]</span> : Nom de l'entreprise
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>
