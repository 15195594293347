<script>
import EventServices from "@/services/EventServices";
import EventMessageVariablesPartial from "../partials/EventMessageVariablesPartial.vue";

import { mapGetters } from "vuex";

import SMSUtils from "@/helpers/SMSUtils";

export default {
  components: {
    EventMessageVariablesPartial,
  },
  data() {
    return {
      submitting: false,

      fields: {
        gicam_campaign_sender_id: 1,
        sms_content: "",
      },

      new_event: null,
    };
  },

  computed: {
    ...mapGetters("system", ["senders"]),
    ...mapGetters("events", ["currentEvent"]),
    isModified() {
      return (
        this.currentEvent?.gicam_campaign_sender_id !==
          this.fields.gicam_campaign_sender_id ||
        this.currentEvent?.sms_content !== this.fields.sms_content
      );
    },
    firstCible() {
      return this.currentEvent.members[0];
    },
    smsCount() {
      return SMSUtils.countLength(this.smsExemple ?? "");
    },
    smsExemple() {
      return this.fields.sms_content
        .trim()
        .replace(/[\r\n]/gm, "")
        .replace(/\[nom\]/gm, this.firstCible.dg)
        .replace(/\[entreprise\]/gm, this.firstCible.name);
    },
  },

  mounted() {},

  created() {
    this.initDatas();
  },

  methods: {
    initDatas() {
      this.fields = {
        gicam_campaign_sender_id: this.currentEvent.gicam_campaign_sender_id,
        sms_content: this.currentEvent.sms_content,
      };
    },

    handleReset() {
      this.initDatas();
    },

    async handleSubmit() {
      this.$startLoading();

      await EventServices.update(this.currentEvent.id, {
        ...this.fields,
      })
        .then(async ({ data }) => {
          if (data.error === false) {
            this.new_event = data.data.event;

            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            await this.$saSuccess("Mise à jour effectuée !");

            this.initDatas();
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la mise à jour !");
        });
    },
  },
};
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div>
      <b-row>
        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="des-sender_name-name-input">
              Nom d'envoie
            </label>
            <select
              class="form-select"
              id="des-sender_name-name-input"
              v-model="fields.gicam_campaign_sender_id"
              required
            >
              <option
                v-for="sender of senders"
                :key="sender.id"
                :value="sender.id"
              >
                {{ sender.name }}
              </option>
            </select>
          </div>
        </b-col>

        <b-col lg="12">
          <EventMessageVariablesPartial />

          <b-row>
            <b-col lg="6">
              <div>
                <div class="d-flex justify-content-between">
                  <label class="form-label" for="des-info-description-input">
                    Contenu du SMS
                  </label>
                  <span class="text-warning">
                    {{ smsCount.remaining }} ({{ smsCount.messages }})
                  </span>
                </div>
                <textarea
                  v-model.trim="fields.sms_content"
                  class="form-control"
                  id="des-info-description-input"
                  rows="3"
                  required
                ></textarea>
              </div>
            </b-col>
            <b-col lg="6">
              <div>
                <label class="form-label" for="des-info-description-input">
                  Exemple d'affichage
                </label>
                <textarea
                  :value="smsExemple"
                  readonly
                  disabled
                  class="form-control"
                  id="des-info-description-input"
                  rows="3"
                  required
                ></textarea>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          :disabled="!isModified"
          @click.prevent="handleSubmit"
        >
          Enregistrer les modifications
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click.prevent="handleReset"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>
</template>
