<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";

import SettingsGeneralForm from "./forms/SettingsGeneralForm.vue";
import SettingsSMSForm from "./forms/SettingsSMSForm.vue";
import SettingsEmailForm from "./forms/SettingsEmailForm.vue";

import { mapGetters } from "vuex";
import store from "@/state/store";

export default {
  page: {
    title: "Configurations",
  },
  data() {
    return {
      title: "Configurations",
      items: [
        {
          text: "GICAM",
          href: "/",
        },
        {
          text: "Evénements",
          href: "/",
        },
        {
          text: "Paramètres",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    SettingsGeneralForm,
    SettingsSMSForm,
    SettingsEmailForm,
  },
  methods: {},

  async beforeRouteEnter(to, from, next) {
    await store.dispatch("events/findOne", to.params.eventId);

    next();
  },

  computed: {
    ...mapGetters("events", ["currentEvent"]),
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="
            $router.replace({
              name: 'campagnes-events-details',
              eventId: currentEvent.id,
            })
          "
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>

      <!--end col-->
      <div class="col-xxl-12">
        <div class="card">
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#configGeneral"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Général
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#configSMS"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Config. SMS
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#configEmail"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Config. Email
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <!--end tab-pane-->

              <div class="tab-pane active" id="configGeneral" role="tabpanel">
                <SettingsGeneralForm />
              </div>

              <div class="tab-pane" id="configSMS" role="tabpanel">
                <SettingsSMSForm />
              </div>
              <div class="tab-pane" id="configEmail" role="tabpanel">
                <SettingsEmailForm />
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
