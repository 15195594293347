<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { mapGetters } from "vuex";

import EventServices from "@/services/EventServices";

export default {
  components: {
    FlatPickr: flatPickr,
  },
  data() {
    return {
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },

      submitting: false,

      fields: {
        name: null,
        event_start_date: null,
        event_end_date: null,
        description: "",
      },

      new_event: null,
    };
  },

  computed: {
    ...mapGetters("events", ["currentEvent"]),
    isModified() {
      return (
        this.currentEvent?.name !== this.fields.name ||
        this.currentEvent?.event_start_date !== this.fields.event_start_date ||
        this.currentEvent?.event_end_date !== this.fields.event_end_date ||
        this.currentEvent?.description !== this.fields.description
      );
    },
  },

  created() {
    this.initDatas();
  },

  methods: {
    initDatas() {
      this.fields = {
        name: this.currentEvent.name,
        event_start_date: this.currentEvent.event_start_date,
        event_end_date: this.currentEvent.event_end_date,
        description: this.currentEvent.description,
      };
    },

    handleReset() {
      this.initDatas();
    },

    async handleSubmit() {
      this.$startLoading();
      this.submitting = true;

      await EventServices.update(this.currentEvent.id, {
        ...this.fields,
        event_start_date: this.fields.event_start_date,
        event_end_date: this.fields.event_end_date,
      })
        .then(async ({ data }) => {
          if (data.error === false) {
            this.new_event = data.data.event;

            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            this.$saSuccess("Mise à jour effectuée !");

            this.initDatas();
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la mise à jour !");
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div>
      <b-row>
        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="steparrow-gen-info-email-input">
              Intitulé
            </label>
            <input
              v-model="fields.name"
              type="text"
              class="form-control"
              id="steparrow-gen-info-email-input"
              required
            />
          </div>
        </b-col>
        <b-col lg="6">
          <div class="mb-3">
            <label class="form-label" for="steparrow-gen-info-username-input">
              Prévu du
            </label>

            <FlatPickr
              v-model="fields.event_start_date"
              id="des-info-date-input"
              :config="dateTimeConfig"
              class="form-control"
            ></FlatPickr>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="mb-3">
            <label class="form-label" for="steparrow-gen-info-username-input">
              Au
            </label>

            <FlatPickr
              v-model="fields.event_end_date"
              id="des-info-date-input"
              :config="dateTimeConfig"
              class="form-control"
            ></FlatPickr>
          </div>
        </b-col>
      </b-row>
      <div v-show="false">
        <label class="form-label" for="des-info-description-input">
          Description
        </label>
        <textarea
          v-model="fields.description"
          class="form-control"
          id="des-info-description-input"
          rows="3"
          required
        ></textarea>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          :disabled="!isModified"
          @click.prevent="handleSubmit"
        >
          Enregistrer les modifications
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click.prevent="handleReset"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>
</template>
