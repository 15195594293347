<script>
import EventServices from "@/services/EventServices";
import EventMessageVariablesPartial from "../partials/EventMessageVariablesPartial.vue";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { mapGetters } from "vuex";

export default {
  components: {
    EventMessageVariablesPartial,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },

      editor: ClassicEditor,

      submitting: false,

      fields: {
        email_subject: null,
        email_title: "",
        email_content: "",
      },

      new_event: null,
    };
  },

  computed: {
    ...mapGetters("system", ["senders"]),
    ...mapGetters("events", ["currentEvent"]),
    isModified() {
      return (
        this.currentEvent?.email_subject !== this.fields.email_subject ||
        this.currentEvent?.email_content !== this.fields.email_content ||
        this.currentEvent?.email_title !== this.fields.email_title
      );
    },
    firstCible() {
      return this.currentEvent.members[0];
    },
    emailExemple() {
      const theContent = this.fields.email_content
        .replace(/\[nom\]/gm, this.firstCible.nom)
        .replace(/\[entreprise\]/gm, this.firstCible.entreprise);

      return `<table cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width: 600px; margin: 0 auto; margin-bottom: 0 !important">
    <tr>
      <td style="padding: 20px 0; text-align: center; background-color: #106454;">
        <h1 style="color: #ffffff;">${this.fields.email_title}</h1>
      </td>
    </tr>
    <tr>
      <td style="padding: 20px;">
        ${theContent}
      </td>
    </tr>
    <tr>
      <td style="padding: 20px; text-align: center; padding-top: 0px !important">
        <img src="/qr-code-sample.png" alt="QR Code" width="100" height="100" />
      </td>
    </tr>
  </table>`;
    },
  },

  mounted() {},

  created() {
    this.initDatas();
  },

  methods: {
    initDatas() {
      this.fields = {
        email_subject: this.currentEvent.email_subject,
        email_title: this.currentEvent.email_title,
        email_content: this.currentEvent.email_content,
      };
    },

    handleReset() {
      this.initDatas();
    },

    async handleSubmit() {
      this.$startLoading();

      await EventServices.update(this.currentEvent.id, {
        ...this.fields,
      })
        .then(async ({ data }) => {
          if (data.error === false) {
            this.new_event = data.data.event;

            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            this.$saSuccess("Mise à jour effectuée !");

            this.initDatas();
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la mise à jour !");
        });
    },
  },
};
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div>
      <b-row>
        <b-col lg="6">
          <div class="mb-3">
            <label class="form-label" for="steparrow-gen-info-email-input">
              Sujet
            </label>
            <input
              v-model="fields.email_subject"
              type="text"
              class="form-control"
              id="steparrow-gen-info-email-input"
              required
            />
          </div>
        </b-col>

        <b-col lg="6">
          <div class="mb-3">
            <label class="form-label" for="steparrow-gen-info-email-input">
              Titre
            </label>
            <input
              v-model="fields.email_title"
              type="text"
              class="form-control"
              id="steparrow-gen-info-email-input"
              required
            />
          </div>
        </b-col>

        <div class="col-lg-12">
          <div class="mb-3 mt-3">
            <EventMessageVariablesPartial />

            <b-row>
              <b-col lg="6">
                <label for="jobDescription" class="form-label">
                  Corps du message
                </label>
                <ckeditor
                  v-model="fields.email_content"
                  :editor="editor"
                ></ckeditor>
              </b-col>
              <b-col lg="6">
                <label for="jobDescription" class="form-label">
                  Exemple de rendu
                </label>
                <div
                  class="mt-3 p-4 border border-secondary"
                  v-html="emailExemple"
                ></div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-row>
    </div>

    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          :disabled="!isModified"
          @click.prevent="handleSubmit"
        >
          Enregistrer les modifications
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click.prevent="handleReset"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>
</template>
